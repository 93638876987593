var _a, _b;
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import App from "./components/App";
import WindowRepair from "./components/WindowRepair";
import TyreService from "./components/TyreService";
import Stenskott from "./components/Stenskott";
import { PageTrackingProvider } from "@volvo-cars/tracking";
var AppWithTracking = function (_a) {
    var apiEndpoint = _a.apiEndpoint, iframeEndpoint = _a.iframeEndpoint, retailerId = _a.retailerId, serviceId = _a.serviceId;
    var location = useLocation();
    var getPageName = function () {
        switch (location.pathname) {
            case "/":
                return "book a service";
            case "/glasreparation":
                return "book a window repair";
            case "/hjulskifte":
                return "book a tyre Service";
            case "/stenskott":
                return "book a windscreen chip repair";
            default:
                return "Unknown Page";
        }
    };
    return (React.createElement(PageTrackingProvider, { event: "page_view", page_name: getPageName(), page_type: "local page", country_code: "se", market_language: "sv-se" },
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: React.createElement(App, { apiEndpoint: apiEndpoint, iframeEndpoint: iframeEndpoint, preSelectRetailer: retailerId, preSelectService: serviceId }) }),
            React.createElement(Route, { path: "/glasreparation", element: React.createElement(WindowRepair, null) }),
            React.createElement(Route, { path: "/hjulskifte", element: React.createElement(TyreService, null) }),
            React.createElement(Route, { path: "/stenskott", element: React.createElement(Stenskott, null) }))));
};
// This injects the main App component into the div with class name: fbi-tacdis-iframe-target
var className = "fbi-tacdis-iframe-target";
var foundElements = document.getElementsByClassName(className);
console.log("Found " +
    foundElements.length +
    " target elements with class name: " +
    className);
for (var i = 0; i < foundElements.length; i++) {
    var elem = foundElements.item(i);
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var apiEndpoint = elem.getAttribute("data-api-endpoint");
    var iframeEndpoint = elem.getAttribute("data-iframe-endpoint");
    var retailerId = (_a = urlParams.get("retailerId")) !== null && _a !== void 0 ? _a : elem.getAttribute("data-retailer-id");
    var serviceId = (_b = urlParams.get("serviceId")) !== null && _b !== void 0 ? _b : elem.getAttribute("data-service-id");
    var app = (React.createElement(HashRouter, { basename: "/" },
        React.createElement(AppWithTracking, { apiEndpoint: apiEndpoint, iframeEndpoint: iframeEndpoint, retailerId: retailerId, serviceId: serviceId })));
    ReactDOM.render(app, elem);
}
