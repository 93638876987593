// Contains the state which decides which component to load
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { Arrow, Spacer, Spinner, StyleProvider, Text, ThemePicker, } from "vcc-ui";
import { fetchRetailerCodesAndServices } from "../../lib/api";
import { ProgressIndicator } from "../ProgressIndicator";
import RetailersList from "../RetailersList";
import ServicesList from "../ServicesList";
import TacdisIFrame from "../TacdisIFrameWindowRepair";
import "./styles.scss";
export default function (props) {
    var _this = this;
    var apiEndpoint = "https://vcs.gvcs.fbinhouse.se/vcs-tacdis-iframe-injector"; // Default to live service
    if (window.location.host === "localhost") {
        apiEndpoint = ".."; // Default to localhost service
    }
    if (typeof props.apiEndpoint === "string") {
        apiEndpoint = props.apiEndpoint;
    }
    var iframeEndpoint = apiEndpoint;
    if (typeof props.iframeEndpoint === "string") {
        iframeEndpoint = props.iframeEndpoint;
    }
    var _a = useState(null), retailers = _a[0], setRetailers = _a[1];
    var _b = useState(null), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = useState(false), isTacdisDisabled = _c[0], setIsTacdisDisabled = _c[1];
    useEffect(function () {
        if (false) {
            // Set to false when tacdis comes back online.
            setIsTacdisDisabled(true);
        }
        else {
            try {
                (function () { return __awaiter(_this, void 0, void 0, function () {
                    var services, sortedService;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, fetchRetailerCodesAndServices(apiEndpoint)];
                            case 1:
                                services = (_a.sent());
                                sortedService = services.sort(function (a, b) {
                                    return a.branchName.localeCompare(b.branchName);
                                });
                                setRetailers(sortedService);
                                return [2 /*return*/];
                        }
                    });
                }); })();
            }
            catch (e) {
                setErrorMessage(e.message);
            }
            if (typeof props.preSelectRetailer === "string") {
                // console.log("Setting the retailer id to: " + props.preSelectRetailer);
                setSelectedRetailerId(props.preSelectRetailer);
            }
            if (typeof props.preSelectService === "string") {
                if (["wc", "sb", "wr"].includes(props.preSelectService)) {
                    // Runtime validation is necessary before casting in typescript
                    setSelectedServiceId(props.preSelectService);
                }
                else {
                    setErrorMessage("Invalid service: " + props.preSelectService);
                }
            }
            if (window.dataLayer) {
                window.dataLayer.push({
                    SPA: true,
                });
            }
        }
    }, []);
    var _d = useState(null), selectedRetailerId = _d[0], setSelectedRetailerId = _d[1];
    var _e = useState(null), selectedRetailer = _e[0], setSelectedRetailer = _e[1];
    useEffect(function () {
        if (retailers === null) {
            setSelectedRetailer(null);
        }
        else {
            var found = retailers.find(function (r) { return r.id === selectedRetailerId; });
            if (found) {
                setSelectedRetailer(found);
            }
            else {
                setSelectedRetailer(null);
            }
        }
    }, [retailers, selectedRetailerId]);
    var _f = useState(null), selectedServiceId = _f[0], setSelectedServiceId = _f[1];
    useEffect(function () {
        /*
        console.log("---");
        console.log("props.preSelectRetailer", props.preSelectRetailer);
        console.log("selectedRetailerId", selectedRetailerId, typeof selectedRetailerId);
        console.log("selectedRetailer", selectedRetailer);
        console.log("Service id", selectedServiceId);
        */
    }, [
        props.preSelectRetailer,
        selectedRetailer,
        selectedRetailerId,
        selectedServiceId,
    ]);
    var displayPage;
    displayPage = "retailers";
    if (selectedRetailer === null) {
        displayPage = "retailers";
    }
    else if (selectedServiceId === null) {
        displayPage = "services";
    }
    else {
        displayPage = "iframe";
    }
    return (React.createElement(StyleProvider, null,
        React.createElement(ThemePicker, { variant: "light" },
            React.createElement("div", { className: "app-container" }, isTacdisDisabled ? (React.createElement("div", { className: "tacdisDisabled" },
                React.createElement("h2", null,
                    React.createElement("strong", null, "!"),
                    " Ej m\u00F6jligt att boka digitalt"),
                React.createElement("p", null,
                    "Tyv\u00E4rr kan vi inte erbjuda en digital bokning f\u00F6r din bil just nu. ",
                    React.createElement("br", null),
                    "V\u00E4nligen kontakta verkstaden."))) : errorMessage !== null ? (React.createElement("p", null,
                "Error: ",
                errorMessage)) : retailers === null ? (React.createElement("div", { className: "loading" },
                React.createElement(Spinner, null),
                React.createElement(Text, { variant: "bates", subStyle: "emphasis" }, "Laddar..."))) : (React.createElement(React.Fragment, null,
                (displayPage === "services" ||
                    displayPage === "iframe") && (React.createElement("div", { className: "back-btn", onClick: function () {
                        setSelectedServiceId(null);
                        setSelectedRetailer(null);
                    } },
                    React.createElement(Arrow, { direction: "left", size: 25 }),
                    React.createElement(Text, { variant: "bates", subStyle: "inline-link" }, "Tillbaka till listan"))),
                React.createElement(ProgressIndicator, { displayPage: displayPage }),
                React.createElement(Spacer, null),
                displayPage === "retailers" && (React.createElement(RetailersList, { retailers: retailers, onSelectRetailer: function (id) {
                        // console.log("Setting retailer id from retailerslist", id);
                        setSelectedRetailerId(id);
                    }, type: "windowrepair" })),
                displayPage === "services" && (React.createElement(ServicesList, { retailer: selectedRetailer, onSelectService: function (id) {
                        // console.log("Setting service id from serviceslist", id);
                        setSelectedServiceId(id);
                    } })),
                displayPage === "iframe" && (React.createElement(TacdisIFrame, { endpoint: iframeEndpoint, retailer: selectedRetailer, serviceId: selectedServiceId }))))))));
}
