import React, { useState, useEffect } from "react";
import "./styles.scss";
import { ProgressIndicator } from "../ProgressIndicator";
import { Spacer, StyleProvider, Text, ThemePicker, Arrow } from "vcc-ui";
var bookingOptions = [
    { name: "Bildeve", tenantKey: "02173f2a-d0fe-4144-9078-0afd077d4c24" },
    { name: "Nybergs Bil", tenantKey: "8768672c-88dd-42bf-b204-9af4922e9f51" },
    { name: "Bilbolaget", tenantKey: "70aa0c40-5007-43c6-b201-829b17c5f908" },
];
var Stenskott = function () {
    var _a = useState(null), selectedOption = _a[0], setSelectedOption = _a[1];
    var _b = useState(false), showBookingModule = _b[0], setShowBookingModule = _b[1];
    useEffect(function () {
        if (!document.querySelector('script[src="https://ecom-cdn.tacdis.com/ecom-retailer-booking/production/js/ecom-retailer-booking.umd.js"]')) {
            var script = document.createElement("script");
            script.src =
                "https://ecom-cdn.tacdis.com/ecom-retailer-booking/production/js/ecom-retailer-booking.umd.js";
            script.type = "text/javascript";
            script.async = true;
            document.head.appendChild(script);
        }
    }, []);
    var handleSelectOption = function (option) {
        setSelectedOption(option);
        setShowBookingModule(true);
    };
    var handleBack = function () {
        setShowBookingModule(false);
        setSelectedOption(null);
    };
    return (React.createElement(StyleProvider, null,
        React.createElement(ThemePicker, { variant: "light" },
            showBookingModule && (React.createElement("div", { className: "back-btn", onClick: handleBack },
                React.createElement(Arrow, { direction: "left", size: 25 }),
                React.createElement(Text, { variant: "bates", subStyle: "inline-link" }, "Tillbaka till listan"))),
            React.createElement(ProgressIndicator, { displayPage: "iframe" }),
            React.createElement(Spacer, null),
            React.createElement("div", { className: "stenskott-container" }, showBookingModule ? (React.createElement("div", { className: "booking-module" },
                React.createElement("div", { id: "booking-module-container" }, selectedOption && (React.createElement("ecom-retailer-booking", { key: selectedOption.tenantKey, services: "windscreenchiprepair", apikey: selectedOption.tenantKey }))))) : (React.createElement("div", { className: "card-container" }, bookingOptions.map(function (option) { return (React.createElement("div", { key: option.tenantKey, className: "booking-card", onClick: function () { return handleSelectOption(option); } },
                React.createElement("h3", null, option.name))); })))))));
};
export default Stenskott;
